import WebServiceRequest from '../Api/WebServiceRequest'

class UpdateScript extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Script/Update')
    }
    setParams(data) {
        super.setRequestParamDataObj(data);
    }
}
class CreateScript extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Script/Create')
    }
    setParams(data) {
        super.setRequestParamDataObj(data);
    }
}
class DeleteScript extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Script/DeleteBy')
    }
    setParams(data) {
        super.setRequestParam(data);
    }
}
class GetAllScripts extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Script/GetAll')
    }
    setParams(data) {
        super.setRequestParam(data);
    }
}

export {
    GetAllScripts,
    DeleteScript,
    CreateScript,
    UpdateScript,

};
