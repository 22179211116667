<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >
    <div>
      <b-modal
          id="modal-delete"
          cancelTitle="خیر"
          centered
          ok-title="بله"
          @ok="deleteScript"
      >
        <span>آیا از حذف کردن این اسکریپت اطمینان دارید ؟ </span>
      </b-modal>
      <b-modal
          id="modal-create"
          cancelTitle="بستن"
          centered
          ok-title="ثبت"
          @ok="createScript"
      >
        <b-row>
          <b-col class="my-25" md="6">
            <b-form-group>
              <label>نوع اسکریپت</label>
              <v-select
                  v-model="script.scriptType"
                  :options="scriptTypes"
                  :reduce="name => name.value"
                  label="name"
              />
            </b-form-group>
          </b-col>
          <b-col dir="ltr" class="my-25" md="12">
            <b-form-group>
              <label>کد اسکریپت</label>
              <textarea v-model="script.content" class="form-control"></textarea>

            </b-form-group>
          </b-col>

        </b-row>
      </b-modal>
      <b-modal
          id="modal-edit"
          cancelTitle="بستن"
          centered
          ok-title="بروزرسانی"
          @ok="updateScript"
      >
        <b-row v-if="selectedScript">
          <b-col class="my-25" md="6">
            <b-form-group>
              <label>محل قرارگیری اسکریپت</label>
              <v-select
                  v-model="selectedScript.scriptType"
                  :options="scriptTypes"
                  :reduce="name => name.value"
                  label="name"
              />
            </b-form-group>
          </b-col>
          <b-col dir="ltr" class="my-25" md="12">
            <b-form-group>
              <label>کد اسکریپت</label>
              <textarea v-model="selectedScript.content" class="form-control"></textarea>
            </b-form-group>
          </b-col>

        </b-row>
      </b-modal>
      <!-- Table Container Card -->
      <b-card
          class="mb-0"
      >
        <b-col class="my-2" lg="12" md="12">
          <b-row>
            <b-col class="my-2" lg="2" md="12">
              <b-button
                  v-b-modal.modal-create
                  variant="primary"
              >
                افزودن اسکریپت جدید
              </b-button>
            </b-col>
            <b-col class="my-2 ml-auto" lg="2" md="12">
              <clear-sorts-btn @clear="sortBy = ''"></clear-sorts-btn>
            </b-col>

          </b-row>
        </b-col>


        <b-table
            ref="refUserListTable"
            :fields="myTableColumns"
            :items="scripts"
            bordered
            class="position-relative"
            :sort-by.sync="sortBy"
            empty-text="موردی موجود نیست!"
            primary-key="id"
            responsive
            show-empty
            striped
        >


          <template #cell(scriptType)="data">
            <b-badge
                v-if="data.item.scriptType===1"
                pill
                variant="primary"
            >
              IIFE
            </b-badge>
            <b-badge
                v-if="data.item.scriptType===2"
                pill
                variant="primary"
            >
              third-party
            </b-badge>
          </template>
          <!-- Column: delete -->

          <template #cell(operation)="data">
            <div class="d-flex align-items-center justify-content-between" @click="setSelectedScript(data.item)">

              <div v-b-modal.modal-delete
                   class="cursor-pointer d-flex flex-row">
                <feather-icon class="text-danger" icon="TrashIcon" size="20"/>
              </div>
              <div v-b-modal.modal-edit
                   class="cursor-pointer d-flex flex-row">
                <feather-icon class="text-primary" icon="EditIcon" size="20"/>
              </div>

            </div>
          </template>


        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <!-- Pagination -->
            <b-col
                class="d-flex align-items-center justify-content-end "
                cols="12"
                sm="12"
            >

              <b-pagination
                  v-model="currentPage"
                  :per-page="perPage"
                  :total-rows="totalCount"
                  class="mb-0 mt-1 mt-sm-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon"
                                size="18"
                                style="transform: rotate(180deg)"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>


  </b-overlay>
</template>

<script>

import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BFormFile,
  BBadge, BDropdown, BDropdownItem, BFormTextarea, BPagination, BOverlay, BModal, BFormGroup, BFormSelect, BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import {DeleteScript, CreateScript, UpdateScript, GetAllScripts} from "@/libs/Api/scripts";
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Helper from "@/libs/Helper";
import ClearSortsBtn from "@/views/components/clearSortsBtn.vue";

export default {
  title: 'لیست اسکریپت ها  - پنل ادمین انزا مد',
  name: 'scriptsIndex',
  data() {
    return {
      baseURL: Helper.baseUrl,
      currentPage: 1,
      perPage: 10,
      totalCount: 0,
      scripts: null,
      sortBy: '',
      selectedScript: null,
      showOverlay: true,
      myTableColumns: [
        // {
        //   key: 'hId',
        //   label: 'hId'
        // },
        {
          key: 'content',
          label: 'کد',
          sortable: true
        },
        // {
        //   key: 'type',
        //   label: 'نوع'
        // },
        // {
        //   key: 'scriptType',
        //   label: 'نوع اسکریپت'
        // },
        {
          key: 'operation',
          label: 'عملیات'
        },

      ],
      script: {
        hId: "",
        type: "text/javascript",
        content: "",
        scriptType: 1
      },
      scriptTypes: [
        {
          name: 'تگ هد (<head>)',
          value: 1
        },
        {
          name: 'تگ فوتر (<footer>)',
          value: 2
        }
      ]


    }
  },
  async created() {
    await this.getAllScripts()
  },
  components: {
    ClearSortsBtn,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormFile,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,
    BFormSelect,
    BFormTextarea,
    vSelect,
  },
  watch: {

    currentPage: function () {
      this.getAllScripts()
    }
  },

  methods: {
    async deleteScript() {
      let _this = this
      _this.showOverlay = true
      let deleteScript = new DeleteScript(_this)
      let data = {
        id: _this.selectedScript.scriptId
      }
      deleteScript.setParams(data)
      await deleteScript.fetch((content) => {
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `اسکریپت با موفقیت حذف شد`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        _this.selectedScript = null
        _this.getAllScripts()
      }, (err) => {
        console.log(err)
      })
      _this.showOverlay = false
    },
    async createScript() {
      let _this = this
      if (_this.script.content !== '') {
        _this.showOverlay = true
        let createScript = new CreateScript(_this)

        createScript.setParams(_this.script)
        await createScript.fetch((content) => {
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `اسکریپت با موفقیت ساخته شد`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          _this.script = {
            hId: "",
            type: "",
            content: "text/javascript",
            scriptType: 1
          }
          _this.getAllScripts()
        }, (err) => {
          console.log(err)
        })
        _this.showOverlay = false
      } else {
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `لطفا کد اسکریپت را وارد کنید`,
            icon: 'AlertIcon',
            variant: 'danger',
          },
        })
      }
    },
    async updateScript() {
      let _this = this
      _this.showOverlay = true
      let updateScript = new UpdateScript(_this)
      updateScript.setParams(_this.selectedScript)
      await updateScript.fetch((content) => {
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `اسکریپت با موفقیت بروزرسانی شد`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        _this.script = {
          hId: "",
          type: "",
          content: "text/javascript",
          scriptType: 1
        }
        _this.getAllScripts()
      }, (err) => {
        console.log(err)
      })
      _this.showOverlay = false

    },
    async getAllScripts() {
      let _this = this
      _this.showOverlay = true
      let getAllScripts = new GetAllScripts(_this)
      let data = {
        pageNumber: _this.currentPage,
        count: 10
      }
      getAllScripts.setParams(data)
      await getAllScripts.fetch((content) => {
        _this.scripts = content.data.scripts
        _this.totalCount = content.data.scriptsCount

      }, (err) => {
        console.log(err)
      })
      _this.showOverlay = false

    },
    setSelectedScript(item) {
      this.selectedScript = item
    }
  },
}
</script>

<style scoped>

</style>
